<template>
    <div class="NewsDetails" style="background-color: #F8F9FA;">
        <div class="location">
            <span style="cursor:pointer" @click="goBack">{{newsSource==0?'首页':newsSource==1?'新闻资讯':'搜索结果页'}}</span>
            <span> > </span>
            <span style="font-size: 13px;color: #999;">{{newsDetail.newsTitle}}</span>
        </div>
        <div class="content">
            <div class="banner" v-if="newsDetail.newsmaxPic!=null&&newsDetail.newsmaxPic!=''">
                <img :src="QiNiuPath+newsDetail.newsmaxPic">
            </div>
            <h2>{{newsDetail.newsTitle}}</h2>
            <span>{{newsDetail.publishDate}}</span>
            <a-divider />
            <div class="infor" v-html="newsDetail.newsContent"></div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex';
    import { queryMatchList } from '@/apis/NewsDetail.js';
    export default {
        name: 'NewsDetails',
        data() {
            return {
                newsId: "",
                newsDetail: {},
                newsSource:0
            };
        },
        computed: {
            ...mapState({
                QNPath: state => state.indexStore.QNPath,
                QiNiuPath: state => state.indexStore.QiNiuPath,
                KeyWord: state => state.indexStore.KeyWord
            }),
        }, 
        created() {
            this.newsId = this.$route.query.NewsId;
            this.newsSource = this.$route.query.NewsSource;
            this.getNewsDetail();

        },
        updated() {
            $('video').prop('muted','true');
            $('video').attr('autoplay','true');
        },
        methods: {
            getNewsDetail: function () {
                let that = this;
                let params = {
                    newsId: that.newsId,
                };
                that.$nextTick(() => {
                    queryMatchList(params).then(res => {
                        let msg = res != undefined && res.data != undefined && res.data != null ? res.data : null;
                        if (msg != null && msg.status == 0) {
                            that.newsDetail = msg.result && msg.result.sysNews ? { ...msg.result.sysNews } : {};
                        }
                    })
                })

            },
            goBack: function() {
                const that = this;
                if (that.newsSource==0) {
                    that.$router.push({
                        path: '/'
                    });
                }
                else if (that.newsSource == 1) {
                    that.$router.push({
                        path: '/VNews'
                    });
                }
                else if (that.newsSource == 2) {
                    that.$router.push({
                        path: '/Vsearch'
                    });
                }
               
            }
        }

    }
</script>

<style scoped>
.location{
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 18px;
    font-size: 14px;
    color: #606978;
}
    .NewsDetails {
        padding-top: 20px;
        min-height: calc(100vh - 159px);
        max-height: auto;
    }

        .NewsDetails .content {
            width: 1200px;
            height: auto;
            overflow-y: hidden;
            margin: 0 auto;
            padding: 30px 60px;
            background-color: #fff;
            box-shadow: 0px 4px 14px 0px rgba(0,0,0,0.1);
            text-align: center;
        }

    .banner > img {
        width: 100%;
        height: 380px;
        border-radius: 12px; 
    }

    .content > h2 {
        text-align: center;
        color: #333A48;
        font-size: 30px;
        margin-top: 50px;
        margin-bottom: 10px !important
    }

    .content > span {
        font-size: 14px;
        color: #9AA7B9;
    }

    .content > p {
        font-size: 18px;
        color: #606978;
        line-height: 30px;
        text-align: left;
    }

    .content > p img {
        min-width: 500px;
        max-width: 100%;
        text-align: center;
    }
    .content .infor img{
        max-width: 100%;
    }
    .content .infor{
        text-align: left;
    }
    .infor >>> .video-js{
        width: 700px;
        height: 400px;
        position: relative;
        left: 18%;
        background:none !important;
    }
</style>